@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
$color-1:  #5713d6 ;

$color-2: #07a8ff ;
$color-3: rgb(36, 33, 33);
$color-4: rgb(219, 228, 240);
$color-5: #d61313;
html {
  position: relative;
  min-height: 100%;
}
body {
 
  margin-bottom: 30px;
  min-height: 100%;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  // background-repeat: repeat;
  font-family: 'Inter', sans-serif; 
  display: grid;
  // background: fixed center/cover;
  //background-image: src("img-tgv.png");
  background-color: rgb(238, 238, 248) ;
}
.container {
  width: 100%;
  height: 50px;
//	padding-right:15px;
//	padding-left:15px;
	margin-right:auto;
	margin-left:auto;
  align-items: center;
  
  
}
.btn-logout {
  text-decoration: $color-5;
  color:  $color-5;
  
}


.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: $color-2;
  font-family: 'Inter', sans-serif;
}
 
.header__middle .active {color: $color-2!important;}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: center;
  padding: 0;
  
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;

}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #ffffff;
  margin: 5px 30px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 20%;

  display: inline-block;
 // float: left;
}
.header__middle__menus {
  width: 100%;
  display: inline-block;
  z-index: 20;
 // float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  //float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
  
}
.header__middle {
 
  
  
  @media (min-width:992px) { 
      display: flex;
      
     
      position: relative;
      background-color: rgba(0, 0, 0, 0.8);
      box-shadow: 0 10px 12px rgb(22 33 39);
      margin: 0 10px;
    
     
     
      height: 50px;
      bottom: 40px;
    
   
    }
 
}
.header__middle__logo > a > h1 {
  color: $color-2;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
} .active{
  color: #ff1800;
} 
.header__middle__menus span.text {
  position: absolute;
  left: 177px;
  bottom: 10px;
  font-size: 14px;
  color: $color-5;
}
.sub__menus__arrows{
  position: relative;
  height: 50px;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
 
  background-color: rgba(0, 0, 0, 0.8);
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  
  
  box-shadow: 0 10px 12px rgb(22 33 39);
}
@media (max-width:767px) { 
  .header__middle .active {color: $color-2;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
  .logo-mobile,.logo-sncf-mobile {
    display: none;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: $color-2 ;
}
.menu-item .sub__menus li:hover a{
  color: $color-2 ;
}

.header__middle__logo img{
  max-width: 100px;
  padding-bottom: 15px;
  
  
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 100%;}
.header__middle__logo img {max-width: 100px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: $color-5;}
.main-nav .menu-item:hover > a {color: $color-2 }
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 6px 7px;
  border-radius: 5px;
  margin:  auto 0;
  bottom: 200px;
  margin-right: 50px;
  cursor: pointer;
  position: relative;
  visibility: visible;
  z-index: 10050;
  height: 30px;
  width: 30px;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  z-index: 10007;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 12px rgb(22 33 39);
  border-bottom: 3px solid #ff3d00;
  left: 0;
  right: 0;
  width: 100%;
  top: 100px;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #ffffff;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}
.logo-mobile {
  display: flex;
  position: relative;
  bottom: 120px;
 height: 120px;
 margin: 0  auto;

}
.logo-sncf-mobile {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
 height: 80px;
 margin: 0  auto;

}
.sub__menus {
  top: 71px
}
.logo,.logo-sncf  {
visibility: hidden;
 
}
}

* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
 
  }
  .clearfix{
    clear:both;
  }
  a{
    text-decoration:none;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
  }
  a:hover{
    text-decoration:none;
    outline:none;
  }

  .logo  {
    display: flex;
    position: relative;
   bottom: 10px;
   height: 120px;
   right:  40px;
  
  }
  .logo-sncf {
    position: absolute; 
   height: 70px;
     right: 10px;
      top: 5px ;
  }
  
  