.progress-bar {
    height: 200px;
    width: 100px;
    background-color: #e0e0de;
    position: absolute;
}
.label-styles {
    padding: 5px;
    color: white;
    font-weight: bold;
    visibility: visible;
    background: none;
    
   
}
.oil-lvl {
    position: absolute;
    bottom: 0;
    
}