.oil-tank-1,.grb-tank-1  {
    margin: 0 auto;
    display: flex;
   margin-top: 20px;
   bottom: 20px;
   position: relative;
    width: 40%;
    align-items: center;
    justify-content: center;
}
.engin-1 {
   height: 300px;
    display: flex;
    margin: 0px auto;
  justify-content: center; 
}
.engin-info-1 {
   height: 300px;
    margin: 10px 100px;
}
.oil-grb-tank {
    height: 300px;
    justify-content: center;
    align-items: center;
    margin: 10px;

 .fut{
    width: 150px;
 }
 .edit {
    position: relative;
    width: 20px;
    top: 3px;
    left: 10px;
    cursor: pointer;
 }
 .arrow {
    margin-left: 60px;
     height: 30px;
     width: 30px;
    position: relative;
     cursor: pointer;
     bottom: 25px
  }

h5 {
    position: relative;
    margin-top: 10px;
    text-align: center;
    bottom: 30px;
    
}
h4 {
    text-align: center;
}
}
    .home {   
        margin-bottom: 60px;
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(400px,2fr)) ;
        grid-auto-flow: dense;
        grid-gap: 10px;
        @media(max-width: 425px) {
            grid-template-columns: repeat(auto-fill,minmax(200px,2fr)) ;
        }
    }
.home-title {
    position: relative;
    text-align: center;
    height: 30px;
    bottom: 40px;
    margin: 0 10%;
    border: 1px solid  rgb(255, 255, 255) ;
    box-shadow: 0 10px 12px rgb(22 33 39);
    z-index: 1;
    @media(max-width:639px) {
        height: 60px;
    }
}
.home-title-other {
    position: relative;
    text-align: center;
    height: 30px;
    bottom: 30px;
    margin: 0 10%;
    margin-bottom: 20px;
    z-index: 1;
    border: 1px solid  rgb(255, 255, 255) ;
    box-shadow: 0 10px 12px rgb(22 33 39);
    @media(max-width:646px) {
        height: 60px;
    }
}
.title-engin {
    text-align: center;
    color: blue;
   img {
    cursor: pointer;
   }
 .edit {
    position: relative;
    width: 20px;
    top: 3px;
    left: 10px;
 }
}
.title-other-engin {
    text-align: center;
    color: blue;
    img {
        cursor: pointer;
        left: 5px;
        position: relative;
    }
    .edit {
        position: relative;
         left: 15px;
        width: 20px;
        top: 3px;
     }
}
.engin {
    margin: 20px 0px 10px 0px;
   // width: auto;
    border: 1px solid  rgb(255, 255, 255) ;
    box-shadow: 0 6px 8px rgb(22 33 39);
}
.other-engin {           
            justify-content: center;
            align-content: center;
            display: grid;
            margin: 0 auto;
        grid-template-columns: repeat(auto-fill,minmax(auto, 1fr)) ;
        grid-auto-flow: dense;
        
}
.other-engin-card {
    border-collapse: collapse;
    border: 1px solid  white ;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;   
    h4,h5 {
        text-align: center;
        margin-bottom: 10px;
    }
    .plus {
        position: relative;
        margin: 0 auto;
        display: flex;
        cursor: pointer;
    }
    .info{
        cursor: pointer;
        left: 5px;
        position: relative;
        top: 5px;
    }
    .edit {
        cursor: pointer;
        left: 5px;
        position: relative;
        top: 5px;
        width: 20px;
        
    }
}
.engin-card-container {
    border: 1px solid  white ;
    box-shadow: 0 10px 12px rgb(22 33 39);
    top: 120px ;  
}
.title-grb,.title-oil {
    position: relative;
    bottom: 110px;
    .edit {
        cursor: pointer;
        left: 5px;
        position: relative;
        top: 5px;
        width: 20px;
        z-index: 7;
    }
}
.date-releve {
    position: relative;
    bottom: 60px;
    text-align: center;
}
.arrow {
   display: flex;
   margin: 0 auto;
     height: 30px;
     width: 30px;
     bottom : 10px;
     
    position: relative;
     cursor: pointer;
    // bottom: 25px
}
.info {
    width: 20px;
    height: 20px;
    top: 3px;
    position: relative;
}
.btn-return {
    width: 50px;
    height: 50px;
    background-color:rgb(238, 238, 248);
    position: relative;
    bottom: 150px;
    left: 230px
}
.home-container {
    position: relative;
    top: 100px;
    margin: 0 10px;
}
