.engin-array-tittle,.engin-array-info {
    top: 20px;
    display: flex;
    width: 95%;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    position: relative;
    margin: 0 auto;
    @media(max-width:800px) {
        width: 98%;
         }
}
.engin-array-info:hover {
    background-color: rgba(227, 227, 227, 0.8);
    transition: 0.5s;
    box-shadow: 0 10px 12px rgb(22 33 39);
    
 }
.capteur-content-container {
   display: flex;
   // margin-top: 12px;
    flex: 1;   
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: 20px;
  //  margin-left: 50px;
    h4,h3,h5,button,li {
        position: relative;
        display: flex;
        margin: 0 auto;
        justify-content: center;
    text-align: center;
       // width: 35vh;
        color: rgba(0, 0, 0, 0.801);
        background-color: transparent;
        width: 10%;

        
        .trash,.edit,.check,.returns
          {
                width: 30px;
                cursor: pointer;
            }
       
        .check {
            padding-left: 20px;
        }
        .returns {
            padding-left: 25px;
        }

    }
    .engin-list-date {
        left: 18px;
    }
    
}
.engin-form-update {
    position: relative;
    display: flex;
    flex: 1;
    
}
#formAddEngin, #formAddCapteur {
    max-width: 70px;
    text-align: right;

}
.message-content-container,.station-container {
    // margin-top: 12px;
     flex: 1;   
     display: grid;
     margin-right: 20px;
     width: 100vh;
   //  margin-left: 50px;
   ul {
    display: flex;   
    width: 100%;
   }
   p {
    float: left;
    margin:0px 10px 10px 10px;
    p {
        color: rgba(8, 83, 233, 0.801);
        position: relative;
        margin: 0 auto;
        display: flex;
       // width: 35vh;     
        background-color: transparent;    
     }
   }
     li {
       // position: relative;
         margin: 10px auto;
         display: flex;
        // width: 35vh;
        color: rgba(0, 0, 0, 0.801);
         background-color: transparent;
         width: 10%;
         p {
            color: rgba(8, 83, 233, 0.801);
           // position: relative;
          //  margin: 0 auto;
        //    display: flex;
           // width: 35vh;
            
            background-color: transparent;
            
        }
        input,select {
            height: 20px;
        }
    }
    .trash {
        display: flex;
        right: 10px;
        position: absolute;
        margin: 10px auto;
      //  margin-right: 90px;
       width: 30px;
       cursor: pointer;
    }
    .returns,.check {
      display: flex;
      right: 10px;
             
      position: absolute;
      margin: 10px auto;
      //  margin-right: 90px;
      bottom: 30px;
      width: 30px;
      cursor: pointer;
        
    }
    .soon {
        
        
               
        display: flex;
        margin: 0px auto;
        //  margin-right: 90px;
       
        width: 30px;
        cursor: pointer;
          
      }
      .edit {
        display: flex;
        right: 50px;
       
        position: absolute;
        margin: 10px auto;
        //  margin-right: 90px;
// bottom: 30px;
        width: 30px;
        cursor: pointer;
          
    } 
}
    .message-array-info {
        top: 20px;
        display: flex;
        width: 95%;
        min-height: 50px;
        height:auto;
        background-color: rgba(255, 255, 255, 0.8);
        position: relative;
        margin: 0 auto;
        @media(max-width:800px) {
            width: 98%;
             }
        
    }
    .message-array-info:hover {
        background-color: rgba(212, 212, 212, 0.8);
        transition: 0.5s;
        
     }

     .engin-array-tittle {
        height: 40px;
        background-color:  rgba(212, 212, 212, 0.8);
        h4 {
         //  color:  rgb(255, 255, 255);
         color: black;
        }
    }
.station-container {
    .logo-station-input {
    margin-right: 300px;
    
   }
   .station-logo {
width: 50px;
   }
  
}