
 //@media (min-width:600px) { 
  .signin-form,
  .signup-form,
  .contact-form,
  .settings-form,
  .image-visu

   {
   // left: 25%;
    top: 50px;
    justify-content: center;
    //width: 50%;
    margin: 0 auto;
    //box-shadow: 0 10px 12px rgb(255, 255, 255);
   
    box-shadow: 0 10px 12px rgb(22 33 39);
    display: grid;
    grid-template-rows: repeat(4, 107px);
    padding: 70px 5px 10px 0px;
    position: relative;
    border: 1px solid white;
    opacity: 0.95;
    border-radius: 5px;
    
  }
  .cgu-text {
   
    //top: 30px;
    justify-content: center;
   
    margin: 0 auto;
    
   
    box-shadow: 0 10px 12px rgb(22 33 39);
    display: grid;
    
    padding: 70px 5px 10px 0px;
    position: relative;
    border: 1px solid white;
    opacity: 0.95;
    border-radius: 5px;
  }
  .signin-form {
    height: 300px;
  //  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
   // float: left;
   width: 95%;
   max-width: 500px;
   .connection-span {
    text-align: center;
    color: red;
    position: relative;
    float: left;
    width: 100%;
    top: 2px;
  }
    .passwordEye {
      position: relative;
      float : right;
      bottom :  40px;
      right: 5px;
   
    
   }

  }
  .signup-form {
    height: 600px;
    width: 95%;
    max-width: 500px;
    span {
      // background: red;
       width: 33%;
       height: 6px;
       margin: -8px 0 2px;
       border-radius: 10px;
       visibility: hidden;
       transition: 0.3s;
     }
    
     p {
      background: red;
      width: 33%;
      height: 6px;
      margin: -2px 0 2px;
      border-radius: 50px;
      visibility: hidden;
      transition: 1s;
      
    }
    .passwordEye {
       position: relative;
       float : right;
       bottom :  40px;
       right: 5px;
    }
    #security span {
      display: inline;
    }
    .error input {
      color: red;
      border: 1px solid rgba(255, 0, 0, 0.527);
      
    }
    .error span {
      visibility: visible;
      opacity: 1;
      color: red;
      font-size:small;
     
    }
    
    .progressRed {
      background: red;
      width: 33%;
      visibility: visible;
        
    }
    .progressBlue {
      background: #16d9e7;
      width: 67%;
      visibility: visible;
    }
    .progressGreen {
      width: 100%;
      background: #5ef9a4;
      visibility: visible;
    }
    .validation-connection {
      visibility: visible;
      text-align: center;
      color: greenyellow;
      width: 100%;
      font-size: larger;
      font-weight:bold;
    }
    .validation-span {
      visibility: visible;
      text-align: center;
      color: red;
      width: 100%;
      font-size: larger;
      font-weight: bold;
    }
  }

 .contact-form {
 height: 600px;
  width: 95%;
  max-width: 800px;

}
.settings-form {
  height: 700px;
   width: 95%;
   max-width: 800px;
}
.settings-info, .settings-change-password {
  position: relative;
  bottom: 100px;
  border: 1px solid white;
  opacity: 0.95;
  min-width: 300px;
  max-width: 500px;
  padding:20px;
  height: 350px;
  border-radius: 10px;
  img {
    display: flex;
    position: relative;
    width: 40px;
    margin: 0 auto; 
    cursor: pointer;
    top: 20px;
  }
 
}
.settings-change-password {
  top: 200px;
  height: 200px;
}
 .settings-btn {
  position: relative;
  display: flex;
  top: 200px;
  button {
     justify-content: center;
     text-align: center;
     align-items: center;
    margin: 0 auto;
   }
 }
 div {
   transition: 0.2s;
  }
  label {
    letter-spacing: 1px;
    color: black;
  }
  input[type="text"],
  input[type="password"],
  li[type="text"],
  select[type="text"]
 
 
  {
    margin: 7px 2px 5px 2px;
    border: 1px solid transparent;
    background: white;
    height: 36px;
    width: 95%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    font-size: 1.1rem;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.2s;
    
  }
  input[type="text"],
  textarea[type="text"],
  input[type="password"]
  {
   border: 1px solid blue;
  }
  

 
  
  input[type="submit"],
  button[type="submit"]
  {
    border: none;
    width: 40%;
    background: rgba(0, 0, 0, 0.801);
   // background: linear-gradient(65deg, $color-1, $color-5);
    margin: 12px auto 0;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.2rem;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.3s ease;
    height: 50px;
    color: whitesmoke;
  }
  input[type="submit"]:hover {
    filter: brightness(105%);
    transform: translateX(2px);
  }
  textarea {
    margin: 7px 2px 7px 2px;
    border: 1px solid transparent;
    background: white;
    height: 300px;
    width: 95%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    font-size: 1.1rem;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.2s;
    resize: none
  }
  .contact-title {
    position: relative;
    text-align: center;
    height: 0px;
   
    top: 60px;
    z-index: 1;
}
.settings-title {
  position: relative;
  text-align: center;
  height: 0px;
 
 bottom: 60px;
  z-index: 1;
}
.cgu-text {
  margin: 0 20px;
  @media(max-width:450px) {
margin: 0 5px
  }
  p,h3 {
    text-align: center;
    margin: 20px 0;
  }
}
.title-cgu {
  position: relative;
  text-align: center;
  bottom: 30px;
}
.image-visu {
  position: absolute;
  float: left;
  width: 99%;
  height: 90vh;
  z-index: 100;
  opacity: 1;
  background-color: rgb(238, 238, 248) ;
  left: 4px;
 img {
   width: 100%;

 }
}
.mark-image {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 110;
}
