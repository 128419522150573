.engin-array {
    top: 20px;
    display: flex;
    width: 95%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    margin: 0 auto;
    @media(max-width:800px) {
   width: 98%;
    }
}
.capteur-title-container {
  //  text-align: center;
  //  justify-content: center;
  display: flex;
  // margin-top: 12px;
   flex: 1;   
   justify-content: center;
   text-align: center;
   align-items: center;
  margin-left: 20px;
   // margin-left: 50px;
    h3,h5 {
        
       margin: 0 auto ;
        
        color: $color-4;

    }
    .arrow-top,.arrow-bottom {
      height: 30px;
      position: absolute;
      bottom: 12px;
      left: 65%;
      cursor: pointer;
      @media(max-width:991px) {
        left: 75%;
      }
      @media(max-width:690px) {
        left: 80%;
      }
      @media(max-width:470px) {
        left:90%;
      }
  }
    }
.btn-add-engin {
    top: 10px;
  //  display: flex;
    //float: right;
    width: 95%;
    height: 50px;
  //  background-color: rgba(255, 255, 255, 0.8);
    position: relative;
   
    margin: 0 auto;
    img {
        display: flex;
     //   float: right;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 auto;
      //  margin-right: 90px;
        margin-top: 12px;
       width: 50px;
       cursor: pointer;
    }
   
}