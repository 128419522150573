footer {
    position: absolute;
    display: flex;
    bottom: -150px;
    //right: 0;
   // left: 0;
    width: 100%;
   // min-width: 300px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.8);
    align-items: center;
    justify-content: center;
    
    h5 {
      color: whitesmoke;
      margin-left: 5px;
    }
}
.copyright {
    height: 20px;
}